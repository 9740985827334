@import-normalize;
@import "./style/helpers.scss";
// @import "~antd/dist/antd.dark.css";

body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  font-variant: small-caps;
}

#root {
  height: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

// OVERRIDE FOR ENTYPO ICONS
.entypo-svgicon {
  vertical-align: unset !important;
}

// PLACEHOLDER SVG FOR NSFW TRIGGER
// .App.NSFW {
//   .ant-card,
//   .post-carousel-image {
//     background-color: #17153b;
//     background-image: url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M81.28 88H68.413l19.298 19.298L81.28 88zm2.107 0h13.226L90 107.838 83.387 88zm15.334 0h12.866l-19.298 19.298L98.72 88zm-32.927-2.207L73.586 78h32.827l.5.5 7.294 7.293L115.414 87l-24.707 24.707-.707.707L64.586 87l1.207-1.207zm2.62.207L74 80.414 79.586 86H68.414zm16 0L90 80.414 95.586 86H84.414zm16 0L106 80.414 111.586 86h-11.172zm-8-6h11.173L98 85.586 92.414 80zM82 85.586L87.586 80H76.414L82 85.586zM17.414 0L.707 16.707 0 17.414V0h17.414zM4.28 0L0 12.838V0h4.28zm10.306 0L2.288 12.298 6.388 0h8.198zM180 17.414L162.586 0H180v17.414zM165.414 0l12.298 12.298L173.612 0h-8.198zM180 12.838L175.72 0H180v12.838zM0 163h16.413l.5.5 7.294 7.293L25.414 172l-8 8H0v-17zm0 10h6.613l-2.334 7H0v-7zm14.586 7l7-7H8.72l-2.333 7h8.2zM0 165.414L5.586 171H0v-5.586zM10.414 171L16 165.414 21.586 171H10.414zm-8-6h11.172L8 170.586 2.414 165zM180 163h-16.413l-7.794 7.793-1.207 1.207 8 8H180v-17zm-14.586 17l-7-7h12.865l2.333 7h-8.2zM180 173h-6.613l2.334 7H180v-7zm-21.586-2l5.586-5.586 5.586 5.586h-11.172zM180 165.414L174.414 171H180v-5.586zm-8 5.172l5.586-5.586h-11.172l5.586 5.586zM152.933 25.653l1.414 1.414-33.94 33.942-1.416-1.416 33.943-33.94zm1.414 127.28l-1.414 1.414-33.942-33.94 1.416-1.416 33.94 33.943zm-127.28 1.414l-1.414-1.414 33.94-33.942 1.416 1.416-33.943 33.94zm-1.414-127.28l1.414-1.414 33.942 33.94-1.416 1.416-33.94-33.943zM0 85c2.21 0 4 1.79 4 4s-1.79 4-4 4v-8zm180 0c-2.21 0-4 1.79-4 4s1.79 4 4 4v-8zM94 0c0 2.21-1.79 4-4 4s-4-1.79-4-4h8zm0 180c0-2.21-1.79-4-4-4s-4 1.79-4 4h8z' fill='%237671ef' fill-opacity='0.5' fill-rule='evenodd'/%3E%3C/svg%3E");

//     img {
//       visibility: hidden;
//     }
//   }
// }

body {
  // Root Modal Modifications
  .ant-modal-root {
    .PaddedModal,
    .TermsModal,
    .PrivacyModal {
      .ant-modal-body {
        padding: 25px 50px;
      }
    }

    .EmailBasedFeedModal,
    .PrivacyFocusedToolModal {
      article {
        margin: 15px 0px 25px;
        @include flexbox(row, center, flex-start, nowrap);

        svg {
          margin: 0 5px 0 0;
        }

        span {
          margin: 3px 0;
          display: inline-block;
        }
      }
    }

    // .FAQModal {
    //   width: 620px !important;
    // }

    .ant-form-item-explain {
      margin-bottom: 10px;
    }

    .user-form-actions {
      margin: 0;
    }
  }

  .ant-popover,
  .app-popover {
    max-width: 600px;
  }

  .app-notification {
    outline: 1px solid $warning-color;
  }

  // App Notification
  .app-notification {
    .ant-notification-notice-message {
      font-weight: bold;
    }

    .ant-notification-notice-description {
      white-space: pre-line;

      p {
        margin: 10px 0;
      }
    }
  }

  // App-Popover
  .ant-popover {
    .ant-popover-message-title {
      display: inline-block;
      padding-left: 10px;
    }
    .entypo-svgicon {
      width: 1rem !important;
      height: 1rem !important;
      vertical-align: initial !important;
    }
  }

  .ant-alert-message {
    margin: 0 10px;
  }

  // Timeline
  .ant-timeline-item-tail {
    border-left-color: $light-component-background;
  }

  // Checkbox in-between check
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: $light-component-background;
  }

  // Spin component (Spinner)
  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 50vh;
  }

  // Collapse component
  .ant-collapse,
  .ant-collapse-borderless {
    background-color: transparent;
  }

  // Upload Drag and Drop component
  .ant-form-item-control-input-content {
    .ant-upload.ant-upload-drag {
      background-color: $dark-component-background;
      padding: 10px;
    }
  }

  // Upload
  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: $dark-component-background;
  }

  .ant-card-actions {
    background-color: $component-background;
  }

  // Message component with custom icons
  .ant-message-custom-content {
    .entypo-svgicon {
      width: 1rem !important;
      height: 1rem !important;
      margin-right: 10px;
      vertical-align: initial !important;
    }

    .message-check {
      color: $success-color;
    }
    .message-info {
      color: $info-color;
    }
    .message-cross {
      color: $error-color;
    }
  }

  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: $color-text-white;
  }

  // Multiple selectable with closeable pills
  .ant-select.ant-select-multiple.ant-select-show-search {
    .ant-select-selection-item,
    .ant-select-multiple .ant-select-choice,
    .ant-select-dropdown-menu-item {
      background-color: $light-component-background;
    }
  }

  // Select component active and selected
  .ant-select-item-option-active,
  .ant-select-item-option-selected,
  .ant-select-dropdown-menu-item-active,
  .ant-select-dropdown-menu-item:hover {
    background-color: $dark-component-background !important;
  }

  .ant-dropdown {
    .entypo-svgicon {
      margin-right: 10px;
    }
  }

  .ant-dropdown-menu {
    .entypo-svgicon {
      vertical-align: baseline !important;
    }
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: $light-component-background;
  }

  .ant-tooltip-arrow-content,
  .ant-tooltip-arrow:before,
  .ant-tooltip-inner {
    background-color: $white;
    color: $black-transparent;
  }

  .ant-tooltip-inner .ant-typography {
    background-color: $white;
    color: $black-transparent;

    .ant-typography-warning {
      color: darken($warning-color, 15%);
    }
  }

  .ant-alert-info {
    background-color: rgba($component-background, 0.5);
    border-color: $info-color;
  }

  .ant-alert-warning {
    background-color: rgba($component-background, 0.5);
    border-color: $warning-color;
  }

  .ant-alert-success {
    background-color: rgba($component-background, 0.5);
    border-color: $success-color;
  }

  .ant-alert-error {
    background-color: rgba($component-background, 0.5);
    border-color: $error-color;
  }

  ::selection {
    color: rgba(255, 255, 255, 0.8) !important;
    background: $color-link !important;
  }

  // Tag component
  .ant-tag {
    background-color: $light-component-background !important;
    color: $white-transparent !important;
  }

  // Switch input component
  .ant-switch-loading-icon,
  .ant-switch::after {
    background-color: $black-transparent;
  }

  .ant-switch-inner {
    color: $white-transparent;
  }

  // Slider component
  .ant-slider {
    .ant-slider-rail {
      background-color: $light-component-background;
    }

    .ant-slider-track {
      background-color: $component-background;
    }

    .ant-slider-handle {
      border-color: $primary-color;
    }

    .ant-slider-dot {
      border-color: $light-component-background;
    }

    &:hover .ant-slider-rail {
      background-color: $light-component-background;
    }
  }

  // custom for Dark theme
  // .UploadForm {
  // .ant-switch {
  // 	background-color: $component-background;
  // }

  // .ant-switch::after {
  // 	background-color: $black;
  // }
  // .ant-switch-loading-icon.ant-switch::after {
  // 	color: $primary-color;
  // }

  // .ant-slider-step {
  //   background-color: $component-background;
  // }
  // .ant-slider-dot {
  //   border-color: $black;
  // }
  // .ant-slider-handle {
  //   border-color: $primary-color;
  // }
  // }
  //

  .ant-modal-footer {
    border-top: none !important;
  }

  .ant-input-prefix svg {
    fill: $color-text-dark !important;
  }

  .ant-switch {
    &:not(.ant-switch-checked) {
      background-color: $light-component-background;
    }

    ::after {
      background-color: $dark-component-background !important;
    }
  }

  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background-color: $light-component-background;
  }
}
