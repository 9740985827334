@import "../../style/helpers.scss";

.App {
  .app-spin-loader > .ant-spin-container {
    width: 100%;
    min-height: 100vh;
    @include flexbox(column, center, flex-start);

    > section {
      flex-grow: 1;
    }
  }

  .TermsPage,
  .PrivacyPage,
  .FAQPage {
    .blank-page-body {
      margin-top: 10vh;
      width: 550px;
    }
  }
}

@media (min-width: 600px) {
  .TermsPage,
  .PrivacyPage,
  .FAQPage {
    .blank-page-body {
      width: 100%;
    }
  }
}
