// theme
$primary-color: #655efe;
$info-color: #61caff;
$success-color: #1dc0a6;
$processing-color: #61caff;
// $error-color: rgb(243, 86, 86);

$error-color: #ff4040;
$highlight-color: #f35656;
$warning-color: #ff9659;
$normal-color: #7d829c;
$link-color: #108aee;

$neon-green-color: #41ffa3;
$neon-teal-color: #36bda6;
$neon-pink-color: #ff45b0;
$neon-blue-color: #3ba5ff;
$neon-purple-color: #b144ff;

$dull-yellow-color: #ffb860;
$dull-orange-color: #fb6f55;
$dull-teal-color: #a0f1e8;
$dull-blue-color: #80b1ff;

// background
$white: #ffffff;
$white-transparent: rgba(255, 255, 255, 0.9);
$black: rgba(0, 0, 0, 0.8);
$black-transparent: rgba(0, 0, 0, 0.9);

// V! dark theme
// $body-background: #363b4a;
// $component-background: #444a60;
// $dark-component-background: hsla(227, 17%, 20%, 1);

// V2 dark theme
$body-background: #18181e;
$component-background: #20222b;
$dark-component-background: #0f0f14;
$light-component-background: #303247;

$color-text-white: #d4d9e3;
$color-text-light: #b7b7bf;
$color-text-gray: #9ea5ba;
$color-text-dark: #606577;

$color-link: #467fbf;

$border-radius: 6px;

@mixin flexbox($flex-direction, $alignment, $justify-content, $flex-wrap: nowrap) {
  display: flex;
  flex-direction: $flex-direction;
  align-items: $alignment;
  align-content: $alignment;
  justify-content: $justify-content;
  flex-wrap: $flex-wrap;
}

@mixin transition($timer: 0.3s, $timing-function: cubic-bezier(0.1, 0.1, 0.25, 0.9), $delay: 0s) {
  transition: all $timer $timing-function $delay;
}

@mixin boxshadow($box-shadow: -2px 0 8px rgba(0, 0, 0, 0.75)) {
  box-shadow: $box-shadow;
}
